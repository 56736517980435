import React from 'react'
import Layout from '../components/Layout'
import OnlineClassPage from '../components/OnlineClassPage'

const ItemTemplate = ({ pageContext: { data } }) => {
  
  return (
    <Layout>
      <OnlineClassPage data={data} />
    </Layout>
  )
}

export default ItemTemplate
