import React from 'react'
import Layout from '../components/Layout'

class IndexPage extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {    
    
    return(
      <Layout>
        <div className='general_header classespage_header'style={{color:'#ffffff'}} /> 
        <div className="page_wrapper" >
          <h1 className="section-headline" style={{color:"#ffffff"}}>Thank You</h1>
          <div className="contact_wrapper" >
              <div>
                  <h3>Thank you for Contacting Us</h3>                  
                  <p>One of our team will contact you as soon as possible.</p>
              </div>                                                      
          </div>                        
        </div>    
      </Layout>
    );
  }
}



export default IndexPage



