import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { StaticQuery, graphql } from "gatsby"

function SEO({ pageSEOdata }) {  
  if (pageSEOdata.length > 0) {
    // console.log(pageSEOdata[0].node)
    const lang = `en`
    var meta=[
      {
        name: `description`,
        content: pageSEOdata[0].node.description,
      },
      {
        property: `og:title`,
        content: pageSEOdata[0].node.ogtitle,
      },
      {
        property: `og:description`,
        content: pageSEOdata[0].node.ogdescription,
      },
      {
        property: `og:type`,
        content: pageSEOdata[0].node.ogtype,
      },
      {
        name: `twitter:card`,
        content: pageSEOdata[0].node.twittercar,
      },
      {
        name: `twitter:creator`,
        content: pageSEOdata[0].node.twittercreator,
      },
      {
        name: `twitter:title`,
        content: pageSEOdata[0].node.twittertitle,
      },
      {
        name: `twitter:description`,
        content: pageSEOdata[0].node.twitterdescription,
      },
      {
        name: 'google-site-verification',
        content: pageSEOdata[0].node.googleSiteVerification,
      },
      {
        name: 'keywords',
        content: []
      }
    ]    
    for (var i = 0; i < pageSEOdata[0].node.keywords.keywords.length; i++) {
      meta[9].content.push(pageSEOdata[0].node.keywords.keywords[i])
    }
    
    return (
      <StaticQuery
        query={detailsQuery}
        render={data => {
          return (
            <Helmet
              htmlAttributes={{lang,}}
              title={pageSEOdata[0].node.title}
              titleTemplate={`%s | ${data.site.siteMetadata.title}`}
              meta={meta}
              
            />
          )
        }}
      />
    )
  } else {
    return (
      <div/>
          
    )
  }  
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: [],
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  // title: PropTypes.string.isRequired,
}

export default SEO

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        description
        author
      }
    }
  }
`