import React from 'react';
import { Link, graphql } from 'gatsby';
import Img from 'gatsby-image';
import '../assets/css/main.css'
// import playbutton from '../assets/images/playbutton.png'


class Welcome extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fixed: false
    };
  }
  
  render() {     
    var image = this.props.image  
    
    return (
      <React.Fragment>
        <div style={{marginTop:'5vmin'}}>          
          <div className="welcome_container">
            {/* <div className="welcomeImage">            
              <img src={halfpuck} />
            </div>                       */}
            <div className="welcomeText">
              <h1>Welcome to Allie Crombie Yoga</h1>
              <h3>Yoga for Mindfulness</h3>
              <p>I took my first yoga class 8 years ago and got hooked by the calm and relaxed feeling that comes after a class. As a runner, I have always struggled with flexibility, which is why I originally found yoga. At the time I had no idea how it would drastically change my life for the better. Working in a stressful corporate job, I suffered severe anxiety and panic attacks. Yoga has substantially reduced my anxiety and removed panic attacks from my life entirely.</p>
              <p>I am passionate about continuing my journey to share the knowledge passed to me from my teachers and make a difference to the lives around me.</p>
            </div>            
          </div>
        </div>
      </React.Fragment>
    );
  }
}


export default Welcome
