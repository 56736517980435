import React from "react"
import Img from "gatsby-image"
import fetch from "node-fetch"
import index from '../pages/index';
import thankyou from '../pages/thankyou';

function await_response (time) {
    return new Promise((resolve) => setTimeout(resolve, time));
}
function sendCompanyEmail(e, body) {  
    e.preventDefault()
  
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Access-Control-Allow-Origin", "http://alliecrombieyoga.com.au");

    var raw = JSON.stringify({"subject":"Website Booking","desc":body});

    var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
    };
    

    fetch("https://9tpoahk7h8.execute-api.ap-southeast-2.amazonaws.com/02/contact-us", requestOptions)
    .then(response => response.text())
    .then(result => console.log(result))
    .catch(error => console.log('error', error));
    
    window.location.href = "http://www.alliecrombieyoga.com.au/thankyou";
    
  }


// function submitToAPI(e, emaildata) {
//     var myHeaders = {"Content-Type": "application/json", "Access-Control-Allow-Origin": "http://alliecrombieyoga.com.au"}; //, "Content-Type": "text/plain"};
//     //var myHeaders = {"Content-Type": "application/json", "Access-Control-Allow-Origin": "http://localhost:8000"}; //, "Content-Type": "text/plain"};    

//     var raw = {subject:"Website Booking",desc:"Stephen McDermott\n0455112898\ntest@testing.com\nRelax - Online\nSun March 29th 16:00\n\nThankyou for booking your class with Allie Crombie Yoga\n\nYour booking details are:\nName:\tStephen McDermott\nPhone:\t0455112898\nEmail:\ttest@testing.com\nClass:\tRelax - Online\n\tSun March 29th 16:00\nThe Zoom Class link is: \n\thttps://zoom.us/meeting/register/vJYlcuGhrz4jDpIYlgdPZg0LQB3jG0hkZA\n\nThankyou for booking, we look forward to sharing this practice with you\n\nAllie Crombie Yoga"};

//     var requestOptions = {
//     method: 'POST',
//     headers: myHeaders,
//     body: raw,
//     redirect: 'follow'
//     };


//     var test = {
//         "method": "POST",
//         "type": "cors",
//         "headers": {
//           "Content-Type": "application/json",
//           "Access-Control-Allow-Origin": "http://localhost:8000"
//         },
//         "body": JSON.stringify({"subject": "Website Booking","desc": "Stephen McDermott\n0455112898\njamesklovell@gmail.com\nRelax - Online\nSun March 29th 16:00\n\nThankyou for booking your class with Allie"})
//       }
  
//     fetch("https://9tpoahk7h8.execute-api.ap-southeast-2.amazonaws.com/02/contact-us", test)
//         .then(response => {
//             response.text(); 
            
//         }).then(result => console.log(result)).catch(err => console.log(err))
        
        
// }

class OnlineClassPage extends React.Component {
    constructor(props) {
        super(props);
        this.datetime = "";
        this.classname = "";
        this.zoomlink =  "";
    } 
    state = {
        name: "",
        phone: "",
        email: "",                   
    }
    handleInputChange = event => {
        const target = event.target
        const value = target.value
        const name = target.name
        // console.log(name)
        this.setState({
            [name]: value,            
        })        
    }
    handleSubmit = evnt => {
        // console.log(evnt)
        
        
        var openingMsg = "Thankyou for booking your class with Allie Crombie Yoga\n\n"+"Your booking details are:\n";
        var booking_details = "Name:\t"+this.state.name+"\nPhone:\t"+this.state.phone+"\nEmail:\t"+this.state.email+"\nClass:\t"+this.classname+"\n\t"+this.datetime+"\n";
        var zoom_details = "The Zoom Class link is: \n\t"+this.zoomlink+"\n\n";
        var signoff = "Thankyou for booking, we look forward to sharing this practice with you\n\nAllie Crombie Yoga";
        var clientEmail = openingMsg+ booking_details +zoom_details+signoff;       
        var companyEmail = this.state.name+"\n"+this.state.phone+"\n"+this.state.email+"\n"+this.classname+"\n"+this.datetime+"\n\n"+clientEmail;
        

        if(this.state.email.length > 0){                        
            sendCompanyEmail(evnt, companyEmail);                        
        }
    }

    render() {        
      var data = this.props; 
      
      this.datetime = data.data.eventDateTime;
      this.classname = data.data.eventName;
      this.zoomlink = data.data.link;
      
  
      return ( 
        <div style={{ background: '#fff' }}>    
            <div className='general_header aboutus_header'style={{color:'#ffffff'}} />          
            <div className="page_wrapper"  style={{minHeight:'65vmin'}}>
                <h1 className="section-headline">{data.data.eventName}</h1>
                <div >
                    <Img className="onlineclasspage_img_left" alt="" fluid={data.data.heroImage.fluid} />
                </div>
                
                <div className="booking_wrapper">
                    <p>{data.data.eventDateTime}</p>
                    <p>Online Zoom Class</p>
                    <p>{data.data.description.description}</p>
                    <p>Price: FREE</p>
                    <p>Fill out the form below and you will be emailed a link to the Zoom Class</p>
                    <form className="onlineclasspage_form" id="booking-form" method="post" onSubmit={this.handleSubmit}>
                        <div className="form-group">
                            <h4>Name:</h4>
                            <input type="text" id="name-input" placeholder="Name..." className="form_control" name="name" value={this.state.name} onChange={this.handleInputChange} />
                        </div>
                        <div className="form-group">
                            <h4>Phone:</h4>
                            <input type="text" id="phone-input" placeholder="Phone..." className="form_control" name="phone" value={this.state.phone} onChange={this.handleInputChange}/>
                        </div>
                        <div className="form-group">
                            <h4>Email:</h4>
                            <input type="email" id="email-input" placeholder="Email..." className="form_control" name="email" value={this.state.email} onChange={this.handleInputChange}/>
                        </div>                        
                        <hr style={{border: '1px solid #ffffff'}}/>
                        <button className="contact_submit_button" type="submit">Book Now</button>
                    </form> 
                    
                    <hr style={{border: '1px solid #ffffff'}}/>

                </div>
                
            </div>          
        </div>
        )
    }
}

export default OnlineClassPage
