import React from 'react';
import { Link, graphql } from 'gatsby';
import Img from 'gatsby-image';
import Banner from "../components/banner"
import '../assets/css/main.css'
// import playbutton from '../assets/images/playbutton.png'
// import halfpuck from '../assets/images/half_puck_yellow.png'
// import halfpuck from '../assets/images/half_puck_green.png'

class WelcomeBanners extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fixed: false
    };
  }
  
  render() {     
    var banners = this.props.data;
    // console.log(banners)
    
    return (
      <React.Fragment>
        <div>          
          <div className="welcome_banners_container">
            <Banner BannerData={banners} />
          </div>
        </div>
      </React.Fragment>
    );
  }
}


export default WelcomeBanners
