import React from 'react'
import { Link } from 'gatsby'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import WelcomeBanners from '../components/welcome_banners'
import Welcome from '../components/welcome'
// import VideoSection from '../components/video_sections'
// import YogaInThePark from '../components/yogainthepark_info'
// import ClassesInfo from '../components/classes_info'
// import BookingForm from '../components/BookingForm'


class IndexPage extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {        
    var all_banners = this.props.data.allContentfulHeaderBanner.edges;
    var all_seo = this.props.data.allContentfulPageSeo.edges;
    var pagename = 'index'
    var banners = []    
    var seo = []
    for (var i = 0; i < all_seo.length; i++) {
      var id_exists = false;
      for (var j =0; j < seo.length; j++) {
        if (all_seo[i].node.contentId == seo[j].node.contentId) { id_exists = true;}
      }      
      if (id_exists == false & all_seo[i].node.pageName == pagename) { seo.push(all_seo[i])}
    }    
    for (var i = 0; i < all_banners.length; i++) {      
      var id_exists = false;
      for (var j =0; j < banners.length; j++) {
        if (all_banners[i].node.contentId == banners[j].node.contentId) { id_exists = true;}
      }
      if (id_exists == false & banners.length < 5) { banners.push(all_banners[i])}
    }
    console.log(seo)

    return(
      <Layout>
        <SEO pageSEOdata={seo}/>        
        <WelcomeBanners data={banners}/>
        {/* <Welcome/>        
        <YogaInThePark/>
        <ClassesInfo classes={classes}/>
        <VideoSection videoSrcURL="https://www.youtube.com/embed/oyXgc5m6DkA" videoTitle="Allie Crombie Yoga"/>  */}
      </Layout>
    );
  }
}





export default IndexPage

export const query = graphql`
  query IndexQuery {
    allContentfulHeaderBanner(filter: {siteName: {eq: "Organiklean"}}) {
      edges {
        node {
          title
          subHeading
          buttonText
          image {
            fluid(maxWidth: 1800) {
              base64              
              srcWebp
              srcSetWebp
              aspectRatio
              sizes
              src
              srcSet
            }
          }
          contentId
        }
      }
    }    
    allContentfulPageSeo(filter: {siteName: {eq: "Organiklean"}}) {
      edges {
        node {
          contentId
          description
          googleSiteVerification
          keywords {
            keywords
          }
          ogdescription
          oglocale
          ogtitle
          ogtype
          ogurl
          pageName
          siteName
          title
          twittercard
          twittercreator
          twitterdescription
          twittertitle
        }
      }
    }
  }
`



